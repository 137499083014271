<template>
  <div class="app app-container app-container-about clearfix">
    <div class="common-content content">
      <el-row>
        <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="common-title">
            <p>校长简介</p>
            <span></span>
          </div>
          <el-row :gutter="10" class="mb-30">
            <el-col :span="10"><img :src="aboutInfo.img" alt="" @error="testFun" style="max-width:95%; min-width:95%" class="wow zoomIn"></el-col>
            <el-col :span="14">
              <div class="whoText wow zoomIn" v-html="aboutInfo.info"></div>
            </el-col>
          </el-row>
          <div class="dashedLine"></div>
          <div class="common-title mt-20">
            <p>校长寄语</p>
            <span></span>
          </div>
          <el-row :gutter="10" class="mb-30">
            <el-col :span="10"><img :src="message.img" alt="" @error="testFun" style="max-width:95%; min-width:95%" class="wow zoomIn"></el-col>
            <el-col :span="14">
              <div class="whoText wow zoomIn" v-html="message.info"></div>
            </el-col>
          </el-row>
          <div class="dashedLine"></div>
          <div class="common-title mt-20">
            <p>大事记</p>
            <span></span>
          </div>
          <div data-v-56be1c44="" class="aboutUs-third-content mt-40">
            <div data-v-56be1c44="" class="course-list"
            ><div data-v-56be1c44="" class="course-content">
              <span data-v-56be1c44="" class="dot"></span>
              <span data-v-56be1c44="" class="line"></span>
              <div data-v-56be1c44="" class="title-content">
                <p data-v-56be1c44="" class="title">2021年7月22日</p>
                <p data-v-56be1c44="" class="content_text">郴州博泰职业技能培训学校取得营业登记</p>
              </div>
            </div>
            <div data-v-56be1c44="" class="course-content after-content">
              <span data-v-56be1c44="" class="dot"></span>
              <span data-v-56be1c44="" class="line"></span>
              <div data-v-56be1c44="" class="title-content">
                <p data-v-56be1c44="" class="title">2021年7月19日</p>
                <p data-v-56be1c44="" class="content_text">郴州博泰职业技能培训学校取得办学许可证</p>
                </div>
              </div>
            </div>
          </div>
          <!-- <div data-v-56be1c44="" class="aboutUs-third-content mt-40">
            <div data-v-56be1c44="" class="course-list">
              <div class="course-content" :class="{'after-content' : (index+1) %2 ==0}"
                  v-for="(couItem, index) in courseList"
                  :key="'curse' + index">
                <span class="dot"></span>
                <span class="line"></span>
                <div class="title-content">
                  <p class="title">{{couItem.event_at_show}}</p>
                  <p class="content">{{couItem.event}}</p>
                </div>
              </div>
            </div>
          </div> -->
          <!-- <div class="common-title">
            <p>联系我们</p>
            <span></span>
          </div> -->
          <!-- <div class="baidu-map" ref="baiduMap" id="baiduMap">
            <div class="item-img"></div>
          </div> -->
        </el-col>
      </el-row>
    </div>
    <el-dialog title="提示" :visible.sync="centerDialogVisible" width="30%" center>
      <span>{{errorMsg}}</span>
      <span slot="footer" class="dialog-footer">
        <!--<el-button @click="centerDialogVisible = false">取 消</el-button>-->
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import qs from 'qs'
export default {
  data () {
    return {
      centerDialogVisible: false,
      isShow: true,
      uname: '',
      utel: '',
      umessage: '',
      errorMsg: '',
      courseList: [],
      aboutInfo: {
        img: require('../assets/images/xiaozhang.jpg'),
        info: `<p>彭彩霞，毕业于湖南科技大学汉语言专业。博泰职业技能培训学校校长、劳务帮平台创始人、妤妍度情商教育平台合伙人。</p>
        <p>1994年-20011年，担任中学班主任。从事语文、历史等学科教学工作，多年获得市级优秀老师，学科带头人。</p>
        <p>2011年-2016年，创立劳务邦人力资源服务平台，平台发展至今旗下已设立30多家人力资源服务公司，覆盖全国200多个城市。</p>
        <p>2016年-2021年，重归教育行业，师从中南大学刘孝听院长、德静老师、生命大师Robert等多位知名大师，并创办妤妍度情商教育平台和博泰职业技能培训学校。</p>
        <p>一片丹心终不改，三尺讲台献余生。为天地立心，为生民立命，为往圣继绝学，为万世开太平。本人始终秉承教育的真谛是：以生命唤醒生命，以灵魂唤醒灵魂。做让人民满意的教育，做对国家和政府满意的教育，做对来到我生命中的每个人真正受益的教育。</p>`
      },
      message: {
        img: require('../assets/images/xyqm_proc.jpg'),
        info: `<p>北瞻衡岳之秀，南峙五岭之冲。山川钟秀，人杰地灵。</p>
<!--        <p>在素称湖南的“南大门”的郴州，郴州博泰职业技能培训学校宣告成立了！</p>-->
        <p>博泰职业技能培训学校坚决贯彻执行党和国家的路线、方针、政策，执行上级党委和行政部门的指示。坚持社会主义办学方向，响应政府号召，积极参与举办职业技能教育培训。
        学校依托于劳务邦---一家全国性人力资源服务平台创办的职业技能培训学校。确立以市场为导向，以社会需求为目标的办学理念和专业设置原则，面向市场，服务社会，依法办事，从严治校。
        真正做到为学员考虑、让企业放心、对社会负责，让人民满意。</p>
<!--        <p>郴州博泰职业技能培训学校，硬件设施齐全，师资力量雄厚，环境良好。学校秉承于郴州人的勤劳刻苦，诚信可靠的精神，务必努力打造成培养各类实用人才的储备和输送基地，-->
<!--        即与郴州市经济建设紧密联系，优化教育机构，提高教育专业，增加贴紧市场、零距离服务的新专业来服务社会。-->
<!--        并计划以郴州为基地，利用郴州地理位置优势，以及劳务邦平台优势，面向珠三角，为珠三角各企业输送各类人才，并由此延升至为全国输送人才。打造培训就业一条龙服务。</p> -->
        <p>培养人才为遵旨，促进就业为辅助。先进的教育理念是学校之灵魂，精良的教师团队是学校之根本，现代化的教学设备是办学之基础，优良的教育教学质量是发展之动力。
        站在新的起点上，我们将发扬艰苦创业的拼搏精神，精诚合作的团队精神，大公无私的奉献精神，敢为人先的创新精神，优化管理模式，强化服务意识，乘职业教育的春风，
        把我校打造成一所“人才培训先进，就业优势明显”的职业技能培训学校。</p>
        <p>新时代的博泰职业学校，办学思路清晰、目的明确，开拓创新、与时俱进，我们相信在国家大力推行职业技能培训的政策下，在社会各界的大力支持下，博泰职业学校必将走得更远，办得更大！</p>`
      }
    }
  },
  created () {
    // this.getDevList();
  },
  mounted () {
    // this.map();
  },
  methods: {
    testFun () {
      this.isShow = false
    },
    // 表单提交
    submit () {
      let reg = /^1[34578]\d{9}$/
      if (!this.uname) {
        this.errorMsg = '请输入正确的姓名'
        this.centerDialogVisible = true
        return
      }
      if (!reg.test(this.utel)) {
        this.errorMsg = '请输入正确的号码'
        this.centerDialogVisible = true
        return
      }
      if (!this.umessage) {
        this.errorMsg = '请输入正确的信息'
        this.centerDialogVisible = true
        return
      }
      let obj = {
        name: this.uname,
        contact: this.utel,
        message: this.umessage
      }
      var objs = qs.stringify(obj)
      this.addCus(objs)
    },
    addCus (val) {
      this.$store.dispatch('addCus', val).then(res => {
        console.log(res)
        this.uname = ''
        this.utel = ''
        this.umessage = ''
      })
    },
    map() {
      let x = 121.381;
      let y = 31.233;
      let map = new window.BMap.Map(this.$refs.baiduMap) // 创建Map实例
      let point = new window.BMap.Point(x, y)
      map.centerAndZoom(point, 16) // 初始化地图,设置中心点坐标和地图级别
      map.addControl(new window.BMap.MapTypeControl({ // 添加地图类型控件
        mapTypes: [
          window.BMAP_NORMAL_MAP,
          window.BMAP_HYBRID_MAP
        ]
      }));
      //设置提示信息
      let infoWindow = new window.BMap.InfoWindow("" +
        "<p style='color: #2F77EF;font-size: 24px; '>郴州博泰职业技能培训学校</p>" +
        "<p style='color: #626EA4;font-size: 16px; '>郴州市北湖区燕泉街道燕泉路中天大厦</p>", {
        width: 320,
      })
      map.openInfoWindow(infoWindow, point)
      var marker = new window.BMap.Marker(new BMap.Point(x, y))
      map.addOverlay(marker)
      marker.addEventListener('click', this.overlayStyle)
      map.setCurrentCity('郴州') // 设置地图显示的城市 此项是必须设置的
      //map.enableScrollWheelZoom(true)// 开启鼠标滚轮缩放
      // map.setHeading(64.5);   //设置地图旋转角度
      //map.setTilt(73);       //设置地图的倾斜角度
    },
    overlayStyle(e) {
      var p = e.target;
      if (p instanceof BMapGL.Marker) {
        alert('该覆盖物是点，点的坐标为：' + p.getPosition().lng + ',' + p.getPosition().lat)
      } else if (p instanceof BMapGL.Circle) {
        alert('该覆盖物是圆，其半径为：' + p.getRadius() + '圆中心点坐标：' + p.getCenter().lng + ',' + p.getCenter().lat)
      } else {
        alert('无法获知该覆盖物类型');
      }
    },
    /**
     * 获取发展历程数据
     * */
    getDevList() {
      this.$axios.get('http://www.jigong360.com/api/timelines').then(res => {
        if (res.status === 200) {
          for (let item of Object.values(res.data)) {
            item.forEach(items => {
              this.courseList.push(items)
            })
          }
          this.courseList.forEach((item, index) => {
            if (item.id === 9) {
              this.courseList.splice(index, 1)
            } else if (item.id === 11) {
              this.courseList.splice(index, 1)
            }
          })
          // console.log(this.courseList);
        }
        console.log(1)
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>
<style lang="less" scoped>
  /*当前面使用的全局CSS*/
  @current-input-btn-color: #1ecfe1;
  ::v-deep{
    .whoText p{
      margin:0 0 10px 0;
      text-indent: 25px;
      line-height:1.6;
    }
  }
  .app-container-about {
    flex: 1;
    padding: 20% 0 3% 0;
    background: url("../assets/images/about.png") no-repeat;
    background-size: 100%;
    background-color: #fafafa;
    .content {
      width: 70%;
      padding: 3% 5%;
      background: @global-white;
      font-size: .88rem;
      text-align: left;
      h4 {
        color: @global-black;
      }
      span {
        color: @global-black;
      }
      .content_left {
        img {
          width: 100%;
          margin-left: -2.5rem;
          height: 100%;
        }
      }
      .alignCenter{text-align: center;}
      .profile-list{
        font-size: 16px;
        font-weight: 400;
        color: #626ea4;
        line-height: 40px;
        margin-bottom: 70px;
      }
      .dashedLine{margin-top:15px; padding-top:15px; border-top:1px dashed #d7dce5}
      .mt-40{margin-top:40px}
      .common-title{
        margin:0 0 60px 0;
        &.mt-20{
          margin-top:20px;
        }
        p{
          margin:0;
        }
      }
      .infoImg{
        width:450px; float:left; margin:0 10px 10px 0;
      }
      .whoText{
        text-align: left;
        line-height:1.6;
        color: #626ea4;
        font-size: 13px;
        p{

        }
      }
      .mb-30{
        margin-bottom: 40px;
      }
      .content_right {
        form {
          margin-top: 1.3rem;
          span {
            margin-left: .6rem;
          }
          input,.ly {
            background: #edf1f4;
            border: 1px solid #edf1f4;
            border-radius: 3px;
            width: 70%;
            margin-bottom: .75rem;
            margin-top: .25rem;
          }
          .ly {
            textarea {
              background: #edf1f4;
              resize: none;
            }
          }
          #btn {
            width: 71%;
            height: 2.5rem;
            line-height: 2.5rem;
            background: @current-input-btn-color;
            color: @global-white;
            border: 1px solid @current-input-btn-color;
          }
        }
      }
      .content_foot {
        p {
          margin-right: 1rem;
          .textnowrap;
          font-size: 0.75rem;
        }
        b {
          font-weight: normal;
          .textwrap;
          color: @global-black;
          width: 95%;
          display: inline-block;
        }
      }
    }
    .aboutUs-third-content{
      margin-bottom: 120px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      .course-list{
        min-width: 590px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        .course-content{
          position: relative;
          padding-right: 40px;
          width: 50%;
          height: 100px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          .dot{
            position: absolute;
            top: 50%;
            right: -5px;
            content: "";
            width: 4px;
            height: 4px;
            padding: 5px;
            border-radius: 50%;
            background-color: #e6e9ef;
            border: 3px solid #fff;
            z-index: 10;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
          }
          .line{
            width: 3px;
            height: 100%;
            background: #edeef0;
            position: absolute;
            top: 0;
            right: 4px;
            z-index:1;
          }
          &:nth-of-type(odd){
            text-align: right;
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
          }
          &:nth-of-type(2n){
            text-align: left;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            .title-content{
              position: relative;
              left: 360px;
            }
          }
        }

        .title-content{
          p{
            margin:0; padding:0;
          }
          .title{
            font-size: 16px;
            font-weight: 400;
            color: #626ea4;
            line-height: 30px;
          }
          .content_text{
            font-size: 13px;
            font-weight: 400;
            color: #626ea4;
            line-height: 30px;
          }
        }
      }
    }
    .baidu-map {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 680px;
      border: 1px #FAFAFA solid;
      overflow: hidden;

      .item-img {

      }
    }
  }
  @media screen and (max-width: 767px){
    .app-container-about {
      .content {
        .infoImg{
          width:90%;
        }
        .course-list{
          min-width: 100%;
          .course-content{
            height: 180px;
            padding-right:15px;
           }
          .course-content:nth-of-type(2n) .title-content{
              left:150px;
            }
        }

        .content_foot {
          .el-col {
            .flex;
            align-items: normal;
            p {
              margin: 0 .5rem 0 0;
            }
          }
        }
      }
    }
  }
</style>
